// extracted by mini-css-extract-plugin
export var contactInfo = "resume-module--contactInfo--e65ec";
export var downloadButton = "resume-module--downloadButton--9c1c1";
export var expereienceItem = "resume-module--expereienceItem--37b9c";
export var headerBackground = "resume-module--headerBackground--d3ed6";
export var headerBackgroundImage = "resume-module--headerBackgroundImage--e890b";
export var headerContainer = "resume-module--headerContainer--23c23";
export var headerImage = "resume-module--headerImage--92b9b";
export var headerName = "resume-module--headerName--1975d";
export var headerNameContainer = "resume-module--headerNameContainer--efb06";
export var headerTitle = "resume-module--headerTitle--c38ae";
export var loadingBox = "resume-module--loadingBox--5c07e";
export var loadingSpinner = "resume-module--loadingSpinner--ad26f";
export var opacity = "resume-module--opacity--cbe9f";
export var section = "resume-module--section--4b7cb";
export var sideProject = "resume-module--sideProject--ab3ca";
export var sideProjectsContainer = "resume-module--sideProjectsContainer--3423c";
export var skillGrid = "resume-module--skillGrid--1e3a1";
export var spin = "resume-module--spin--1a891";